import {SiteLocale} from '../../../__generated__/datocms.types'
import {TranslocoService} from '@ngneat/transloco'
import {Language} from '../i18n/language'

export function getSiteLocale(locale: TranslocoService): SiteLocale {
  return toSiteLocale(locale.getActiveLang())
}

export function toSiteLocale(lang: string): SiteLocale {
  switch (lang) {
    case 'en':
      return SiteLocale.En
    case 'fr':
      return SiteLocale.Fr
    case 'de':
      return SiteLocale.De
    case 'it':
      return SiteLocale.It
    default:
      throw RangeError(`Unsupported lang ${lang}`)
  }
}

export function fromSiteLocale(locale: SiteLocale): Language {
  switch (locale) {
    case SiteLocale.De:
      return Language.GERMAN
    case SiteLocale.En:
      return Language.ENGLISH
    case SiteLocale.Fr:
      return Language.FRENCH
    case SiteLocale.It:
      return Language.ITALIAN
    default:
      throw RangeError(`Unsupported locale ${locale}`)
  }
}
